@import "https://fonts.googleapis.com/css?family=Nunito";
@import "https://fonts.googleapis.com/css?family=Nunito";
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html, body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 13px;
    font-weight: 300;
    font-family: Poppins;
}
:root {
	--background-color: #003768;
	--form-body-background: #f5f5f5;
	--navbar-color: #d0dcea;
	--label-color: #3e495a;
	--placeholder-color: #78879a;
	--form-text-h5-color: #00376;
	--form-text-p-color: #78879a;
	--input-background: #dcdcdc;
	--input-background-focus: #dcdcdc;
	--input-color: #333;
	--input-color-icon: #78879a;
	--lost-password-color: #78879a;
	--lost-password-hover-color: #007bff;
	--lost-password-border-color: #78879a;
	--divider-text-color: #3e495a
}

body {
	margin: 0;
	padding: 0;
	height: 100vh;
	background-color: var(--background-color) !important;
	font-family: 'Nunito', sans-serif
}
/* TOASTR BUGFIX */
#toast-container > div {
  opacity: 1;
}
.toast {
  font-size: initial !important;
  border: initial !important;
  backdrop-filter: blur(0) !important;
}
.toast-success {
  background-color: #51A351 !important;
}
.toast-error {
  background-color: #BD362F !important;
}
.toast-info {
  background-color: #2F96B4 !important;
}
.toast-warning {
  background-color: #F89406 !important;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus,
button, button:focus {
	box-shadow: 0 0 0 rgba(0, 0, 0, 0);
	outline: 0 none !important;
}
.cdk-overlay-container {
	z-index: 1130 !important;
}
.h-85 {
	height: 85vh
}
.mrg-rt-5 { margin-right: 5px; }
.mrg-lt-5 { margin-left: 5px; }
.padding-10 {
	padding: 10px !important;
}
.form-page-header { 
	padding: 0px 10px 0px 10px; 
}
.form-page-header label {
	font-weight: 400;
}
.search-results {
	/**padding: 20px 5px;**/
	    display: inline-block;
    font-weight: 500;
}
.tab-appoinment .mat-tab-label {
	min-width: 20px;
    padding: 0 15px;
}
.client-lists .initial-text{
	width: 50px;
	padding: 16px;
    display: inline-block;
	border-radius: 50px !important;
	font-weight: 400;
	color: #FFF;
}
.client-lists .form-check-input {
	margin-top: 1.5rem
}
.client-lists .form-check {
	margin: 0 1rem 0 1.5rem;
}
.client-lists.check-all .form-check  {
	margin: 0 1rem 0 0;
}
.client-lists .result-date  {
	padding: 0 19px 0 0;
}
.client-lists .media-body {
	padding: 5px 0;
}
.client-lists .list-unstyled {
    padding: 0 30px;
}
.client-lists ul>li {
    margin-bottom: 0.1rem;
    padding: 10px 10px 3px;
    border-bottom: 1px solid #e8e8e8;
}
.client-lists .fa, .client-lists .fas {
	font-weight: 900;
}
.pagination .mat-paginator-page-size-label, 
.pagination .mat-paginator-range-label {
    font-size: 14px !important;
    color: #000 !important ;
}
.pagination .mat-paginator-page-size-select {
    text-align: right !important;
}
.center {
	flex: 1;
	display: flex;
	align-items: center
}
.padding-0 {
    padding: 0 !important;
}
.white-bg  {
    background-color: #FFF !important;
}
.section-container {
    margin-top: 20px;
}
.section-container>.white-bg {
	overflow: hidden;
    overflow-y: auto;
    margin: 0;
}
.subheader {
    margin-left: 32px;
    color: #FFF;
	margin-bottom:-4px;
}
.subheader h5 {
    color: #b1b1b1;
}
.right-section {
    height: 100%;
}
.right-section {
    flex: 1;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.top-menu-container {
	padding: 10px;
	padding-bottom: 2px;
}
.top-menu.bg-grey {
	background-color: #f5f5f5 !important;
}
.top-menu-container a {
	color: #FFF !important;
	margin-right: 12px;
    cursor: pointer;
}
.top-menu-container a.btn-icon-back {
	border: 1px solid #cacaca;
	padding: 4px 7px;
	color: #a7a7a7 !important;;
}

.client-modal {
	display: flex;
	flex-direction: column;
}
  
.client-modal > * {
width: 100%;
}
.client-modal .mat-form-field {
	width: 100% !important;
}
.top-menu-container-dd>.mat-menu-content>button.mat-menu-item {
	height: 33px;
	line-height: 26px;
}
ul.left-section-menu li .tooltip {
    left: -20px !important;
    font-size: 18px !important;
}

.pac-container{
    z-index:9999999 !important;
    display: block !important;
}

.pac-container:after {
	background-image: none !important;
	display: none !important;
}
.client-modal .form-group {
	margin-bottom: 0 !important;
}
.edit-profile-tab .mat-tab-label {
	min-width: 100px;
	height: 30px !important;
	padding: 0px 20px
}
.client-recur .mat-radio-outer-circle, .client-recur .mat-radio-inner-circle, 
.client-recur .mat-checkbox-inner-container {
    width: 15px;
    height: 15px;
}
.client-modal .mat-radio-label-content, 
.client-modal .mat-checkbox-label {
	font-size: 11px;
	line-height: 12px;
}
.client-modal .mat-radio-container,
.client-modal .mat-checkbox-container {
    width: 15px;
	height: 15px;
	top: -2px;
}
.client-recur {
	margin-top: 10px;
}

.ng-sidebar { 
	background: #FFF;
	box-shadow: 1px 0px 5px #333;
	z-index: 999!important;
}
bs-dropdown-container {
    z-index: 1111;
}
.btn-collapse {
	position: absolute;
	top:45%;
	left:0;
	width: 10px;
	height: 40px;
	background: #007bff;
	z-index: 1000;
}
.btn-collapse.right {
	right: 0;
	left: auto;
}
.btn-collapse>i {
	font-size: 23px;
    color: #FFF;
    margin-top: 9px;
    margin-left: 3px;
}
.ng-sidebar .client-lists {
	width: 100%;
	margin-top: 30px;
}
.ng-sidebar .client-lists.check-all .list-unstyled {
	padding: 0
}
.ng-sidebar .client-lists .media-body {
	padding: 12px 6px;
}
.ng-sidebar .client-lists .initial-text {
    width: 31px;
    padding: 6px;
    display: inline-block;
    border-radius: 42px !important;
    font-weight: 400;
    color: #FFF;
    text-align: center;
}
.client-lists.check-all ul>li.media {
    margin-bottom: 1rem;
}
.ng-sidebar .client-lists .form-check-input {
    margin-top: 3px;
}
.ng-sidebar .client-lists.check-all .media .form-check .form-check-input {
	margin-top: 15px;
}
.ng-sidebar .client-lists.check-all .list-unstyled .mat-icon-button {
	line-height: 17px;
    height: 28px;
    margin-top: -11px;
}
.ng-sidebar .client-lists .hd-btn-link {
	padding: 0 5px;
}
.ng-sidebar .client-lists .list-unstyled {
    padding: 0;
}
.result-list .media-body {
	border-bottom: 1px solid #eaeaea;
	border-bottom: 1px solid #eaeaea;
    margin-bottom: 1rem;
	padding: 0 0 10px;
	margin-left: 10px;
}

/* 20-07-19*/
.form-group.selectType mat-radio-button .mat-radio-label-content{
    display: block;
    margin-bottom: 5px;
}
.form-group mat-radio-button {
    display: block;
}
.form-group mat-radio-button .mat-radio-label-content {
    margin-top: 6px;
    padding: 0 10px;
}

.contentScroll { 
	height: 450px; 
	overflow-y: auto; 
	overflow-x: hidden;
}
.reduce-width .ng-sidebar__content {
	width: 71% !important;
	transform: none !important;
}

.country-selector{
	opacity:1 !important;
	bottom:8px !important;
  }
  
  input:not(.country-search){
	bottom: 3px;
	left: 0px;
  }
  
  input{
	text-transform: uppercase;
  }
  .mat-menu-content:not(:empty){
	max-height:250px;
  }

  .mat-flat-button {
	  line-height: 30px !important;
  }

  mat-option > span {
	text-transform: uppercase;
  }

  .mat-select-value {
	text-transform: uppercase;
  }


  .invoice-dialog {
	max-width: 1000px !important;
  }